export const useRefMenus = () => {
  const route = useRoute();

  if (!route.query.ref) return;

  try {
    return (route.query.ref as string)?.split(".").map((id) => parseInt(id));
  } catch (error) {
    console.log(error);
  }
};
